<template>
  <v-dialog v-model="show" persistent width="500">
    <v-card>
      <v-toolbar dense dark color="secondary" tile>
        <v-toolbar-title>
          <span>Firma del cliente</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-3">
        <span class="mb-2 mt-5"
          >Por favor, dibuja en el siguiente recuadro tu firma tal cual aparece
          en tu identificación.</span
        >
        <div class="d-canvas">
          <drawing-canvas
            :image.sync="image"
            width="450"
            lineJoin="round"
            ref="drawingCanvas"
          ></drawing-canvas>
        </div>
        <div v-if="image.length" class="mt-3">
          <v-btn color="secondary" text block @click="resetDraw()">
            <span>Volver a intentar</span>
          </v-btn>
        </div>
        <div class="d-flex justify-end mt-3">
          <v-btn
            color="secondary"
            outlined
            class="mr-2"
            @click="$emit('close')"
          >
            <v-icon small class="mr-w">mdi-close</v-icon>
            <span>Cancelar</span>
          </v-btn>
          <v-btn color="primary" @click="saveSignature()">
            <v-icon small class="mr-2">mdi-draw-pen</v-icon>
            <span>Guardar firma</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VueDrawingCanvas from "vue-drawing-canvas";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    "drawing-canvas": VueDrawingCanvas,
  },

  data() {
    return {
      image: "",
    };
  },

  mounted() {
    this.resetDraw();
  },

  methods: {
    saveSignature() {
      this.$emit("customerSignatureUpdated", this.image);
    },

    resetDraw() {
      this.image = "";
      this.$refs.drawingCanvas.reset();
    },
  },
};
</script>

<style scoped>
.d-canvas {
  border: 2px solid #e3e3e3;
}
</style>
